<template>
  <section class="detail-header">
    <div>
      <div class="title">
        <div class="code">申请编号：{{ data.refundNo }}</div>
        <el-tag class="status" size="mini">{{ getStatusName(data.status) }}</el-tag>
        <div v-if="data.status == 5" class="reason">
          <div style="color: #e0001b">失败原因：{{ data.refundFailReason ? data.refundFailReason : '--' }}</div>
          <div style="color: #f59a23">如失败原因为用户账号注销等相关原因，请联系用户提供打款银行卡账户，线下找易宝走线下打款退款。</div>
        </div>
      </div>
      <div class="detail-info-content">
        <div class="detail-info-item">
          <div class="detail-info-item-name">退款金额：</div>
          <div class="detail-info-item-content">¥{{ data.amount }}</div>
        </div>
        <div class="detail-info-item">
          <div class="detail-info-item-name">申请时间：</div>
          <div class="detail-info-item-content">{{ data.createTime }}</div>
        </div>
        <div class="detail-info-item">
          <div class="detail-info-item-name">支付流水号：</div>
          <div class="detail-info-item-content">
            <el-button type="text" @click="goPayDetail">{{ data.payOrderNo }}</el-button>
          </div>
        </div>
      </div>
      <div class="detail-info-content">
        <div class="detail-info-item">
          <div class="detail-info-item-name">订单类型：</div>
          <div class="detail-info-item-content">
            {{ data.orderType == 0 ? '收款订单' : '加购订单' }}
          </div>
        </div>
        <div class="detail-info-item">
          <div class="detail-info-item-name">订单ID：</div>
          <div class="detail-info-item-content">
            <el-button type="text" @click="goOrderDetail">{{ data.orderNo ? data.orderNo : '--' }}</el-button>
          </div>
        </div>

        <div class="detail-info-item">
          <div class="detail-info-item-name">所属客户：</div>
          <div class="detail-info-item-content">
            <el-button type="text">
              <span v-if="data.customerName" @click="goCustDetail">{{ data.customerName }}</span>
              <span v-else>--</span>
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="btnBox">
      <el-button
        v-if="data.status === 0 && powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_AGREEREFUND')"
        type="primary"
        class="btn"
        size="small"
        @click="refund(data, 2)"
        >同意退款</el-button
      >
      <el-button
        v-if="data.status === 0 && powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_REFUSEREFUND')"
        type="primary"
        class="btn"
        size="small"
        @click="refund(data, 4)"
        >拒绝退款</el-button
      >
      <el-button
        v-if="data.receivePayTypeName"
        type="primary"
        class="btn"
        size="small"
        @click="goToOrderAudit()"
      >
        退款登记记录
      </el-button>
      <!-- <el-button
        v-if="data.status === 5"
        @click="refund(data, 5)"
        type="primary"
        class="btn"
        size="small"
        >退款</el-button
      >
      <el-button
        v-if="data.status === 5"
        @click="refund(data, 6)"
        type="primary"
        class="btn"
        size="small"
        >确认线下退款</el-button
      > -->
    </div>
    <RefundModal :status="status" :dialog-form-visible="dialogFormVisible" :title="title" :row="data" @cancel="cancel" @getTable="getInfo" />
  </section>
</template>

<script>
import RefundModal from '@/components/refundModal/index.vue'
import { dealRefundRecord } from '@/api/pay'

export default {
  name: 'DetailHeader',
  components: {
    RefundModal,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      title: '',
      status: '',
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  methods: {
    goToOrderAudit() {
      window.open(
        `${window.origin}/index/pay/receiveAuditList?orderNo=${this.data.orderNo}&type=2`
      );
    },
    getStatusName(e) {
      switch (e) {
        case 1:
          return '申请退款';
        case 2:
          return '退款中';
        case 4:
          return '已拒绝退款';
        case 7:
          return '已取消';
        case 3:
          return '已退款';
        case 5:
          return '退款失败'
        default:
          return '--'
      }
    },
    cancel() {
      this.dialogFormVisible = false
    },
    goPayDetail() {
      this.$router.push({
        path: '/record/detail',
        query: { id: this.data.payOrderNo, orderNo: this.data.orderNo },
      })
    },
    goOrderDetail() {
      let url = ''
      if (this.data.orderType === 0) {
        url = '/index/service/receivingOrder/receivingParticulars?id='
      } else {
        url = '/index/service/purchaseOrder/purchaseParticulars?id='
      }
      history.pushState(null, null, url + this.data.orderId)
    },
    goCustDetail() {
      history.pushState(null, null, `/index/chance/clientFollowUp/particulars?id=${this.data.customerId}`)
    },
    refund(row, status) {
      let name = ''
      this.row = row
      this.status = status
      switch (status) {
        case 3:
          name = '同意退款'
          break
        case 6:
          name = '线下退款'
          break
        case 2:
          name = '拒绝退款'
          break
        case 5:
          name = '退款'
          break
        default:
          name = ''
      }
      this.title = name
      if (status === 5) {
        this.$confirm(`是否确认${this.title}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            const data = {
              refundIds: [this.row.refundId],
              status: this.status,
            }
            dealRefundRecord({ data }).then(() => {
              this.$message({
                type: 'success',
                message: '操作成功!',
              })
              this.getInfo()
            })
          })
          .catch(() => {})
      } else {
        this.dialogFormVisible = true
      }
    },
    getInfo() {
      this.$emit('getInfo')
    },
  },
}
</script>

<style scoped lang="less">
.detail-header {
  background: #fff;
  display: flex;
  justify-content: space-between;
  .title {
    display: flex;
    padding: 20px 20px;
    .reason {
      font-weight: normal;
      padding-left: 20px;
    }
    .code {
      min-width: 364px;
      font-size: 20px;
      color: #000;
      font-weight: 500;
    }
    .status {
      margin-left: 12px;
      margin-top: 3px;
    }
  }
  .detail-info-content {
    padding: 0 20px;
    display: flex;
    .btn {
      margin-bottom: 20px;
    }
    .detail-info-item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      margin-bottom: 16px;
      .detail-info-item-name {
        color: #9a9d9e;
        font-size: 14px;
      }
      .detail-info-item-content {
        font-size: 14px;
        color: #000;
      }
    }
  }
  .btnBox {
    padding: 20px;
    min-width: 210px;
  }
}
</style>
