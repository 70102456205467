<template>
  <el-dialog
    v-loading="loading"
    width="600px"
    :title="title"
    :visible.sync="visible"
    @close="cancel"
  >
    <el-form v-if="status === 2" ref="ruleForm" :model="ruleForm">
      <div>
        <el-form-item label="备注">
          <el-input v-model="ruleForm.reason" type="textarea" placeholder="请输入内容" maxlength="300" :rows="5" show-word-limit></el-input>
        </el-form-item>
      </div>
    </el-form>
    <el-form v-if="status === 4" ref="ruleForm" :model="ruleForm" :rules="rules">
      <el-form-item prop="reason" label="拒绝原因">
        <el-input v-model="ruleForm.reason" type="textarea" placeholder="请输入内容" maxlength="300" :rows="5" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancel">取 消</el-button>
      <el-button size="small" type="primary" @click="ok">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import { dealRefundRecord } from '@/api/pay'

export default {
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    status: {
      type: Number,
      default: null,
    },
    row: {
      type: [String, Object],
      default: () => {},
    },
    selectRecords: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ruleForm: {
        refundTime: '',
        reason: '',
        certificates: '',
      },
      visible: false,
      rules: {
        reason: [{ required: true, message: '请输入拒绝原因', trigger: 'blur' }],
        refundTime: [{ required: true, message: '请选择打款时间', trigger: 'blur' }],
        certificates: [{ required: true, message: '请上传打款凭证', trigger: 'blur' }],
      },
      fileList: [],
      loading: false,
      action: '',
    }
  },
  watch: {
    dialogFormVisible(val) {
      this.visible = val
    },
  },
  mounted() {
    this.action = process.env.VUE_APP_BASE_URL + 'misc-web-api/common/file/uploadImage'
  },
  methods: {
    cancel() {
      this.$refs.ruleForm.resetFields()
      this.ruleForm = {}
      this.fileList = []
      this.$emit('cancel')
    },
    ok() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$confirm(`是否确认${this.title}?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              let data = {}
              console.log(this.row, 1)
              if (this.row === '批量') {
                const ids = []
                this.selectRecords.forEach((e) => {
                  ids.push(e.refundId)
                })
                data = {
                  refundIds: ids,
                  status: this.status,
                  reason: this.ruleForm.reason,
                }
              } else {
                const arr = []
                this.fileList.forEach((e) => {
                  arr.push(e.response.data.http)
                })
                data = {
                  refundIds: [this.row.refundId],
                  status: this.status,
                  reason: this.ruleForm.reason,
                  refundTime: moment(this.ruleForm.refundTime).format('YYYY-MM-DD HH:mm:ss'),
                  certificates: JSON.stringify(arr),
                }
              }
              this.loading = true
              dealRefundRecord({ data }).then(() => {
                this.loading = false
                this.cancel()
                this.$message({
                  type: 'success',
                  message: '操作成功!',
                })
                this.$emit('getTable')
              })
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.form {
  ::v-deep.el-form-item__label {
    margin-right: 10px;
  }
}
::v-deep .el-textarea .el-input__count {
  color: #909399;
  background: #fff0;
  position: absolute;
  font-size: 12px;
  bottom: -10px;
  right: 25px;
}
</style>
