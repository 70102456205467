<template>
  <div v-loading="loading" class="refund-record-detail">
    <detail-header :data="data" @getInfo="getInfo" />
    <div style="padding: 0px 20px 20px;background-color: #fff;">
    <section class="refund-record-content" >

      <div class="info">
        <div>
          <common-tilte title="退款产品信息" />
          <div style="border: 1px solid #e0e0e0">
            <div class="product">
              <div class="img-box">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="data.orderProduct.coverPictureUrl1"
                >
                <div slot="error" class="image-slot" style="line-height: 110px">
                  <i class="el-icon-picture-outline" style="font-size: 25px"></i>
                </div>
                </el-image>
                <div class="product-info">
                  <div class="titleName">{{ data.orderProduct.productName }}</div>
                  <div v-if="data.orderType == 1">
                    <p>规格：{{ data.orderProduct.specification }}</p>
                    <p>计费单位：{{ data.orderProduct.priceUnit }}</p>
                  </div>
                </div>
              </div>
              <div class="charge">
                <div>¥{{ data.orderType == 1 ? data.orderProduct.salePrice : data.orderProduct.payAmount }}</div>
                <div v-if="data.orderType == 1">x{{ data.orderProduct.number }}</div>
              </div>
            </div>
            <div v-for="(item, i) in data.orderProduct.subList" :key="i" class="product">
              <div class="img-box">
                <el-image style="width: 100px; height: 100px" :src="item.coverPictureUrl"> </el-image>
                <div class="product-info">
                  <div class="titleName">{{ item.productName }}</div>
                  <div v-if="data.orderType == 1">
                    <p>规格：{{ item.specification }}</p>
                    <p>计费单位：{{ item.priceUnit }}</p>
                  </div>
                </div>
              </div>
              <div class="charge">
                <div>¥{{ data.orderType == 1 ? item.salePrice : item.payAmount }}</div>
                <div v-if="data.orderType == 1">x{{ item.number }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <common-tilte title="退款原因" />
          <div class="reason">
            <div class="name">退款原因：</div>
            <div class="reason-text">
              {{ data.reason }}
            </div>
          </div>
        </div>
        <div>
          <common-tilte title="证明照片" />
          <div v-if="(data.imgs ||[]).length" class="demonstrate-imgs">
            <el-image
              v-for="(img, i) in data.imgs"
              :key="i"
              style="width: 100px; height: 100px"
              :src="img"
              :preview-src-list="[img]"
            >
            </el-image>
          </div>
          <div v-else class="empty">暂无证明照片~</div>
        </div>
        <div v-show="data.receivePayTypeName" class="item" style="margin-top: 20px">
          <common-tilte title="打款信息" />
          <div class="payouts">
            <span>
              <span class="payouts-title">退款方式</span>
              <span class="payouts-text">{{ data.receivePayTypeName }}</span>
            </span>
            <span>
              <span class="payouts-title">打款时间</span>
              <span class="payouts-text">{{ data.payDate }}</span>
            </span>
            <span>
              <span class="payouts-title">打款商户</span>
              <span class="payouts-text">{{ data.merchantName }}</span>
            </span>
          </div>
          <!-- <span class="info-title">打款凭证</span>
        <el-image
          v-for="(item, index) in form.imgs"
          :key="index"
          style="width: 140px; height: 140px; margin-right: 20px"
          :src="item"
          :preview-src-list="form.imgs"
        >
        </el-image> -->
        </div>
        <div v-if="data.receivePayTypeName">
          <common-tilte title="打款凭证" />
          <div v-if="(data.voucherPics||[]).length" class="payment-credentials">
            <el-image
              v-for="(img, i) in data.voucherPics"
              :key="i"
              style="width: 100px; height: 100px"
              :src="img"
              :preview-src-list="[img]"
            >
            </el-image>
          </div>
          <div v-else class="empty">暂无打款凭证~</div>
        </div>
      </div>
      <div class="record-time-line">
        <common-tilte title="退款申请记录" />
        <div class="record-time-line-content">
          <el-timeline>
            <el-timeline-item v-for="(item, i) in data.logsDto" :key="i">
              <div class="record-item">
                <div class="time">
                  {{ item.createTime }} <span class="name"> {{ getStatusName(item.status) }}</span>
                </div>
                <p v-if="item.status == 6">操作人：{{ item.createUserName }}</p>
                <p v-if="item.status == 3 || item.status == 4">审核人：{{ item.createUserName }}</p>
                <div v-if="item.status === 1">
                  <p>退款金额：¥{{ item.amount }}</p>
                  <p class="desc">退款原因：{{ item.reason }}</p>
                  <div v-if="item.imgs && item.imgs.length > 0">
                    <span>证明照片</span>
                    <div>
                      <el-image v-for="(img, ind) in item.imgs" :key="ind" style="width: 100px; height: 100px; margin: 4px" :src="img" :preview-src-list="[img]"> </el-image>
                    </div>
                  </div>
                </div>
                <div v-if="item.status === 4 || item.status === 3">
                  <div class="desc">审核备注：{{ item.reason }}</div>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </section>
  </div>
  </div>
</template>

<script>
import DetailHeader from './components/DetailHeader.vue';
import CommonTilte from '@/components/common-title/index.vue';
import { queryUserOrderRefund, findByBusinessId } from '@/api/pay';

export default {
  name: 'RefundRecordDetail',
  components: {
    DetailHeader,
    CommonTilte,
  },
  data() {
    return {
      data: {
        orderProduct: {},
        voucherPics: []
      },
      loading: false,
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getStatusName(e) {
      switch (e) {
        case 1:
          return '申请退款';
        case 2:
          return '退款中';
        case 4:
          return '已拒绝退款';
        case 7:
          return '已取消';
        case 3:
          return '已退款';
        case 5:
          return '退款失败'
        case 6:
          return '确认线下打款'
        default:
          return '--'
      }
    },
    getInfo() {
      this.loading = true
      const data = {}
      if (this.$route.query.refundId) {
        data.refundId = this.$route.query.refundId
      }
      if (this.$route.query.orderServiceId) {
        data.orderServiceId = this.$route.query.orderServiceId
      }
      queryUserOrderRefund({
        data,
      }).then((res) => {
        this.loading = false
        this.data = res
        this.data.imgs = this.data.imgs ? JSON.parse(this.data.imgs) : []
        this.data.certificates = this.data.certificates ? JSON.parse(this.data.certificates) : []
        this.data.logsDto.forEach((e) => {
          if (e.imgs) {
            e.imgs = JSON.parse(e.imgs)
          }
        });
        this.data.logsDto.reverse();
        this.findByBusinessId(this.data.refundId)
      });
    },
    async findByBusinessId(id) {
      const params = { data: id };
      const data = await findByBusinessId(params);

      if (!data) return;

      const { voucherPics, receivePayTypeName, payDate, merchantName } = data;
      this.$set(this.data, 'voucherPics', voucherPics);
      this.$set(this.data, 'payDate', payDate);
      this.$set(this.data, 'merchantName', merchantName);
      this.$set(this.data, 'receivePayTypeName', receivePayTypeName);
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
.empty{
  color: #96999E;
  text-align: center;
  font-size: 15px;
}
.demonstrate-imgs {
  .el-image {
    margin: 8px;
  }
}
::v-deep.title {
  font-weight: bold !important;
}
.desc {
  word-wrap: break-word;
  word-break: normal;
}
.payouts {
  display: flex;
  margin-bottom: 20px;
  & > span {
    margin-right: 64px;
  }
  &-title {
    color: #9a9d9e;
    margin-right: 16px;
  }
  &-text {
    color: #363f47;
  }
}
</style>
